export const Days: { value: string, name: string }[] = Array.from({ length: 31 }, (_, i) => {
    const day = (i + 1).toString();
    return { value: day, name: day };
});

export const Months: { value: string, name: string }[] = Array.from({ length: 12 }, (_, i) => {
    const month = (i + 1).toString();
    return { value: month, name: month };
});


export const Years:{ value: string, name: string }[] = Array.from({ length: 21 }, (_, i) => {
    let currentYear = new Date().getFullYear();
    const year = (currentYear + i).toString();
    return { value: year, name: year };
});
